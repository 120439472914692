@import url("https://fonts.googleapis.com/css2?family=Macondo&display=swap");

body {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Center horizontally */
  font-family: "Macondo", cursive;
  background: rgb(165, 20, 31);
  background: radial-gradient(circle,
      rgba(165, 20, 31, 1) 0%,
      rgba(110, 4, 12, 1) 100%);
  margin: 0;
  min-height: 100vh;
  padding-top: 20px;
  /* Add some padding to the top */
}

h1 {
  color: rgb(240, 180, 0);
  text-align: center;
}

ul {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform-origin: left;
  transition: all 0.3s ease;
  padding: 0;
}

li {
  width: 100%;
  display: flex;
  justify-content: center;
}

.scroll-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;
  margin: 20px;
  min-width: 300px;
  cursor: pointer;
  transform: scale(1.1);
  /* Scale up by 20% */
}

/* scroll head */
.scroll-container .head {
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  z-index: 1;
  user-select: none;
}

.scroll-container .head .left {
  height: 20px;
  width: 20px;
  position: relative;
}

.scroll-container .head .right:before,
.scroll-container .head .left:before {
  content: " ";
  height: 80%;
  width: 50%;
  top: 0;
  margin: 10% 0;
  left: 0;
  position: absolute;
  background: rgb(241, 186, 27);
  background: linear-gradient(0deg,
      rgba(241, 186, 27, 1) 0%,
      rgba(255, 240, 160, 1) 50%,
      rgba(241, 186, 27, 1) 100%);
  box-shadow: 0 0 6px 0px #1f1f1fa3;
  z-index: 1;
}

.scroll-container .head .right:after,
.scroll-container .head .left:after {
  content: " ";
  height: 60%;
  width: 50%;
  top: 0;
  margin: 20% 0;
  right: 0;
  position: absolute;
  background: rgb(177, 176, 174);
  background: linear-gradient(90deg,
      rgba(177, 176, 174, 1) 0%,
      rgba(249, 241, 214, 1) 51%);
  box-shadow: 0 0 6px 0px #1f1f1fa3;
}

.scroll-container .head .main {
  height: 20px;
  flex: 1;
  width: calc(100% - 20px);
  background: rgb(119, 6, 20);
  background: linear-gradient(0deg,
      rgba(119, 6, 20, 1) 0%,
      rgba(205, 65, 82, 1) 50%,
      rgba(119, 6, 20, 1) 100%);
  box-shadow: 0 0 6px 0px #1f1f1fa3;
}

.scroll-container .head .right {
  height: 20px;
  width: 20px;
  position: relative;
  transform: scale(-1);
}

.scroll-container .paper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  background: linear-gradient(to top,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.183) 50%,
      rgba(255, 255, 255, 0) 100%),
    #7e0811;
  position: relative;
  width: calc(100% - 48px);
  overflow: hidden;
  max-height: 400px;
  transition: all 0.5s ease;
  box-shadow: 0 0 8px 0px #1f1f1fa3;
}

.scroll-container .paper.close {
  max-height: 0;
}

.scroll-container:hover .paper.close {
  max-height: 400px;
}

.scroll-container .paper::before {
  position: absolute;
  content: " ";
  background-image: url("https://ucarecdn.com/da215040-0c56-4959-8d41-e016f72d0716/");
  background-position: center;
  background-size: contain;
  width: 10px;
  height: 100%;
  left: 5px;
  top: 0;
  opacity: 0.5;
}

.scroll-container .paper::after {
  position: absolute;
  content: " ";
  background: url("https://ucarecdn.com/da215040-0c56-4959-8d41-e016f72d0716/");
  background-position: center;
  background-size: contain;
  width: 10px;
  height: 100%;
  right: 5px;
  top: 0;
  opacity: 0.5;
  overflow: hidden;
}

.scroll-container .content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 12px;
  flex-direction: column;
  background-color: #c6c8aa;
  width: calc(100% - 44px);
  min-height: 300px;
  max-width: 300px;
  border-radius: 2px;
  box-sizing: border-box;
  padding: 0.8em;
  font-size: 12px;
  z-index: 1;
  position: relative;
}

.scroll-container .content:before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  background: url(https://ucarecdn.com/2d36e888-9e4c-4353-9c00-eb087f75a511/-/preview/-/quality/smart/);
  background-size: 40px;
  border-bottom-right-radius: 100%;
  background-position: center;
  background-repeat: repeat;
  height: 40px;
  aspect-ratio: 1;
  z-index: -1;
}

.scroll-container .content:after {
  content: " ";
  position: absolute;
  bottom: 0;
  right: 0;
  background: url(https://ucarecdn.com/2d36e888-9e4c-4353-9c00-eb087f75a511/-/preview/-/quality/smart/);
  background-size: 40px;
  border-top-left-radius: 100%;
  background-position: center;
  background-repeat: repeat;
  height: 40px;
  aspect-ratio: 1;
  z-index: -1;
}

.scroll-container .content h2 {
  font-size: 14px;
}

.content span {
  display: inline-flex;
  margin: 0.8em;
  gap: 0.6em;
}

input {
  width: 10px;
  height: 10px;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 2px;
  position: relative;
}

input:before {
  content: "×";
  height: 100%;
  width: 100%;
  bottom: 0;
  left: 0;
  /*background-color: #c6c8aa;*/
  position: absolute;
  border-radius: 2px;
  font-size: 18px;
  font-weight: 600;
  line-height: 10px;
  background-color: #c6c8aa;
  cursor: pointer;
}

input:checked:before {
  content: "✓";
}

input:checked {
  width: 10px;
  height: 10px;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 2px;
  box-shadow: 0 0 4 1 2px black inset;
  position: relative;
}

.El-khetm {
  position: absolute;
  bottom: 10px;
  right: 20px;
}

.fanus {
  z-index: 99;
  display: flex;
  justify-content: center;
  margin-top: -20px;
}

.fanus img {
  max-width: 80px;
  transform-origin: top;
  animation-duration: var(--order);
  animation-name: swining;
  animation-iteration-count: infinite;
}

@keyframes swining {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(3deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.tokenomics-title {
  color: rgb(240, 180, 0);
  text-align: center;
  margin-top: 20px;
}

/* Header styles */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  width: 100%;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  /* Add a background color to the header */
}

.header-left h1 {
  margin: 0;
}

.header-right {
  display: flex;
  gap: 10px;
}

.icon {
  width: 32px;
  height: 32px;
}

.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
  position: relative;
  z-index: 1001;
}

.hamburger .bar {
  width: 25px;
  height: 3px;
  background-color: #ffffff;
  margin: 4px 0;
  transition: transform 0.3s ease-in-out;
}

.hamburger.open .bar:nth-child(1) {
  transform: translateY(7px) rotate(45deg);
}

.hamburger.open .bar:nth-child(2) {
  opacity: 0;
}

.hamburger.open .bar:nth-child(3) {
  transform: translateY(-7px) rotate(-45deg);
}

.menu-open .hamburger {
  display: none;
}

/* Ensure menu items are displayed in a column */
.menu-items {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 60px;
  left: 0;
  width: 100%;
  height: calc(100% - 60px);
  background-color: #fff;
  padding: 20px;
  box-sizing: border-box;
  transition: transform 0.3s ease-in-out;
  transform: translateX(-100%);
  z-index: 1000;
  /* Ensure this is lower than the close button */
}

.menu-items.open {
  transform: translateX(0);
}

.menu-items a {
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .header-right {
    display: none;
  }

  .hamburger {
    display: flex;
  }

  .close-icon {
    display: flex;
  }
}

/* Menu styles */
.menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.menu-content {
  text-align: center;
}

.menu-content h1 {
  color: #fff;
}

.menu-content .icon {
  margin: 10px;
}

/* Image section styles */
.image-section {
  text-align: center;
  margin-bottom: 20px;
  margin-top: 35px;
}

.image-container {
  position: relative;
  display: inline-block;
}

.cat-image {
  width: 100%;
  height: auto;
}

.border-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}